<template>
    <div class="flex flex-col w-full mb-10">
        <div class="flex w-full">
            <img src="../assets/whyChooseUs.png" class="w-full" alt="">
        </div>
        <div class="flex xl:mx-20 lg:mx-24 mx-4 ">
            <div class="flex flex-row">
                <div class=" font-semibold flex flex-col text-left">
                    <h1 class="text-left xl:text-5xl lg:text-5xl text-2xl font-bold text-one xl:my-12 lg:my-10 my-2 xl:mr-32 lg:mr-32 mr-0">Почему
                        мы?
                    </h1>
                    <p class="mt-4 xl:text-xl lg:text-lg text-md xl:mr-32 lg:mr-32 mr-0  text-one">Наша компания, охватывая всю цепочку работы — от
                        поля до склада заказчика,
                        выступает в качестве связующего звена между таджикскими фермерами и импортёрами со всего мира, чтобы
                        обеспечить широкий ассортимент, необходимые объёмы, высокое качество продукции и услуг, стабильность
                        и
                        лучшее условие для импортёров.
                    </p>
                </div>
            </div>
            <div class="xl:flex lg:flex hidden mr-16 mt-8">
                <img class="w-full" src="../assets/productView.png" alt="">
            </div>
        </div>
    </div>
    <div class="flex justify-center items-center">
        <hr class="bg-one w-11/12 p-0.5 xl:my-12 lg:my-10 mb-4 rounded-2xl ">
    </div>
</template>

<script>
export default {
    name: 'whyChooseUs'
}</script>
