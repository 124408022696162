<template>
    <div class="flex xl:flex-row lg:flex-row flex-col xl:w-full lg:w-full w-11/12 xl:gap-10 lg:gap-10 gap-2 items-center justify-center xl:mx-0 lg:mx-0 mx-4 mt-12">
        <div
            class="xl:w-1/5 lg:w-1/5 w-full xl:h-56 lg:h-56 h-24 flex xl:flex-col lg:flex-col flex-row  
            xl:px-4 lg:px-4 px-1 py-2 xl:justify-center lg:justify-center justify-start items-center rounded-xl bg-yellow-200">
            <svg class="mt-2 xl:w-full lg:w-full w-1/6 xl:h-16 lg:h-16 h-3/5" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="phone">
                    <path id="Vector"
                        d="M7.8667 11.6249C7.8667 10.6701 8.24598 9.75445 8.92111 9.07932C9.59625 8.40419 10.5119 8.0249 11.4667 8.0249H19.2175C20.0696 8.02529 20.894 8.32795 21.544 8.87904C22.1939 9.43012 22.6273 10.1939 22.7671 11.0345L25.4311 27.0005C25.5584 27.7609 25.4382 28.5421 25.0882 29.229C24.7383 29.916 24.177 30.4724 23.4871 30.8165L17.9143 33.5993C19.9127 38.5516 22.8889 43.0503 26.6651 46.8265C30.4413 50.6027 34.94 53.5789 39.8923 55.5773L42.6787 50.0045C43.0226 49.3152 43.5785 48.7544 44.2647 48.4045C44.9509 48.0546 45.7313 47.934 46.4911 48.0605L62.4571 50.7245C63.2977 50.8643 64.0615 51.2977 64.6126 51.9476C65.1637 52.5976 65.4663 53.422 65.4667 54.2741V62.0249C65.4667 62.9797 65.0874 63.8954 64.4123 64.5705C63.7372 65.2456 62.8215 65.6249 61.8667 65.6249H54.6667C28.8187 65.6249 7.8667 44.6729 7.8667 18.8249V11.6249Z"
                        fill="#2A5C29" />
                </g>
            </svg>
            <div class="xl:flex lg:flex hidden flex-col text-center">
                <h1 class="mb-2 mt-6 font-semibold">+992 92 713 85 55</h1>
                <p class="mb-4 text-gray-400">Для звонков по Таджикистану</p>
            </div>
            <!-- mob  -->
            <div class="xl:hidden lg:hidden flex flex-col">
                <h1 class="mb-2 mt-6 font-semibold">+992 92 713 85 55</h1>
                <p class="mb-4 text-gray-400">Для звонков по Таджикистану</p>
            </div>
            <!--  -->
        </div>
        <div
            class="xl:w-1/5 lg:w-1/5 w-full xl:h-56 lg:h-56 h-24 flex xl:flex-col lg:flex-col flex-row  
            xl:px-4 lg:px-4 px-1 py-2 xl:justify-center lg:justify-center justify-start items-center rounded-xl bg-yellow-200">
            <svg class="xl:w-full lg:w-full w-1/6 xl:h-16 lg:h-16 h-3/4 " viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="mail">
                    <path id="Vector"
                        d="M7.21094 22.0074L36.0001 36.4002L64.7893 22.0074C64.6828 20.173 63.8789 18.4487 62.5423 17.1878C61.2057 15.9269 59.4376 15.2246 57.6001 15.225H14.4001C12.5626 15.2246 10.7945 15.9269 9.45798 17.1878C8.12141 18.4487 7.31751 20.173 7.21094 22.0074Z"
                        fill="#2A5C29" />
                    <path id="Vector_2"
                        d="M64.8002 30.0498L36.0002 44.4498L7.2002 30.0498V51.225C7.2002 53.1346 7.95876 54.9659 9.30903 56.3162C10.6593 57.6664 12.4906 58.425 14.4002 58.425H57.6002C59.5098 58.425 61.3411 57.6664 62.6914 56.3162C64.0416 54.9659 64.8002 53.1346 64.8002 51.225V30.0498Z"
                        fill="#2A5C29" />
                </g>
            </svg>
            <div class="xl:flex lg:flex hidden flex-col text-center">
                <h1 class="mb-2 mt-6 font-semibold">donaialmos@inbox.ru</h1>
                <p class="mb-4 text-gray-400">Служба поддержки</p>
            </div>
            <!-- mob  -->
            <div class="xl:hidden lg:hidden flex flex-col">
                <h1 class="my-1 font-semibold">donaialmos@inbox.ru</h1>
                <p class="text-gray-400">Служба поддержки</p>
            </div>
            <!--  -->
        </div>
        <div
            class="xl:w-1/5 lg:w-1/5 w-full xl:h-56 lg:h-56 h-24 flex xl:flex-col lg:flex-col flex-row xl:px-4 lg:px-4 px-1 py-2 justify-center items-center rounded-xl bg-yellow-200">
            <svg class="w-32" width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="location_on_FILL0_wght400_GRAD0_opsz24 1">
                    <path id="Vector"
                        d="M36.333 36.825C37.983 36.825 39.3955 36.2375 40.5705 35.0625C41.7455 33.8875 42.333 32.475 42.333 30.825C42.333 29.175 41.7455 27.7625 40.5705 26.5875C39.3955 25.4125 37.983 24.825 36.333 24.825C34.683 24.825 33.2705 25.4125 32.0955 26.5875C30.9205 27.7625 30.333 29.175 30.333 30.825C30.333 32.475 30.9205 33.8875 32.0955 35.0625C33.2705 36.2375 34.683 36.825 36.333 36.825ZM36.333 58.875C42.433 53.275 46.958 48.1875 49.908 43.6125C52.858 39.0375 54.333 34.975 54.333 31.425C54.333 25.975 52.5955 21.5125 49.1205 18.0375C45.6455 14.5625 41.383 12.825 36.333 12.825C31.283 12.825 27.0205 14.5625 23.5455 18.0375C20.0705 21.5125 18.333 25.975 18.333 31.425C18.333 34.975 19.808 39.0375 22.758 43.6125C25.708 48.1875 30.233 53.275 36.333 58.875ZM36.333 66.825C28.283 59.975 22.2705 53.6125 18.2955 47.7375C14.3205 41.8625 12.333 36.425 12.333 31.425C12.333 23.925 14.7455 17.95 19.5705 13.5C24.3955 9.04995 29.983 6.82495 36.333 6.82495C42.683 6.82495 48.2705 9.04995 53.0955 13.5C57.9205 17.95 60.333 23.925 60.333 31.425C60.333 36.425 58.3455 41.8625 54.3705 47.7375C50.3955 53.6125 44.383 59.975 36.333 66.825Z"
                        fill="#2A5C29" />
                </g>
            </svg>
            <h1 class="xl:mt-6 lg:mt-6 mt-0 font-semibold xl:text-center lg:text-center text-left"> 735700, Таджикистан, город Худжанд, Свободная Экономическая Зона
                Сугд.</h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "the-contact"
}
</script>